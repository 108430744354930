.container {
  z-index: 999;
}

h2 {
  font-size: 1.4em;
  color: #fff;
  font-weight: bold;
  margin-bottom: 15px;
}

h3 {
  font-size: 1.2em;
  color: #fff;
  font-weight: bold;
}

h4 {
  font-size: 1.1em;
  color: #75828f;
  font-weight: bold;
  margin-bottom: 5px;
}

h4 a {
  color: #75828f;
  text-decoration: none;
}

h4 a:hover {
  color: #fff;
  text-decoration: none;
}

header, section, footer {
  margin-top: 30px;
  background: rgba(21, 32, 43, .9);
  padding: 30px;
  color: #75828f;
  border-radius: 15px;
}

header .photo {
  text-align: center;
}

header .photo img {
  border-radius: 50%;
  max-width:  100%;
}

header .name {
  color: #fff;
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 5px;
}

header .title {
  margin-bottom: 15px;
}

header .icons {
  font-size: 1.8em;
  text-align: right;
}

header .icons a {
  color: #75828f;
}

header .icons a:hover {
  color: #fff;
}

.skill {
  display: inline-block;
  margin-bottom: 6px;
  margin-right: 6px;
  padding: 12px;
  background: #1e2732;
  border-radius: 3px;
}

footer {
  text-align: center;
  margin-bottom: 30px;
}

footer a {
  color: #75828f;
  text-decoration: none;
}

footer a:hover {
  color: #75828f;
  text-decoration: none;
}

@media (max-width: 767px) {

  header .photo {
    margin: 0px auto;
    max-width: 50%;
    margin-bottom: 30px;
  }
}